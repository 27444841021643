<template>
    <div>
        <app-header />
        <fade-transition origin="center" mode="out-in" :duration="250">
            <router-view :key="$route.fullPath" />
        </fade-transition>
        <app-footer />
    </div>
</template>
<script>
import AppHeader from "./AppHeader";
import AppFooter from "./AppFooter";
import { FadeTransition } from "vue2-transitions";

export default {
    components: {
        AppHeader,
        AppFooter,
        FadeTransition
    }
};
</script>
